import { render, staticRenderFns } from "./majorBox.vue?vue&type=template&id=b2fb2fb8&"
import script from "./majorBox.vue?vue&type=script&lang=js&"
export * from "./majorBox.vue?vue&type=script&lang=js&"
import style0 from "./majorBox.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports