import { render, staticRenderFns } from "./securityBox.vue?vue&type=template&id=7095dceb&scoped=true&"
import script from "./securityBox.vue?vue&type=script&lang=js&"
export * from "./securityBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7095dceb",
  null
  
)

export default component.exports