<template>
  <header>
    <div class="app-header">
      <div class="logoImg" @click="backHome">
        <el-image :src="url"></el-image>
      </div>
      <div class="topTab" v-if="language === 'zh'">
        <span @click="backHome">世界大学排名</span>
        <span @click="backArtsHome">美国文理学院排名</span>
      </div>
      <div class="topTab" v-if="language === 'en'">
        <span @click="backHome">Global University Ranking</span>
        <span @click="backArtsHome">U.S. Liberal Arts College Ranking</span>
      </div>
      <div class="headerNav">
        <span @click="langChange">{{ languageText }}</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      languageText: "English",
      language: "zh"
    };
  },
  computed: {
    url() {
      if (this.language === "en") {
        return require("../assets/img/icon/logo_en.png");
      } else {
        return require("../assets/img/icon/logo_zh.png");
      }
    }
  },
  created() {
    this.language = localStorage.lang == undefined ? "zh" : localStorage.lang;
  },
  watch: {
    language: {
      handler(nVal) {
        document.title =
          nVal === "zh"
            ? "2020年由你排世界大学排名"
            : "2020 UniRank Global University Rankings";
        this.languageText = nVal === "zh" ? "English" : "中文";
      },
      immediate: true
    }
  },
  methods: {
    //语言切换
    langChange() {
      let _lan = this.language === "zh" ? "en" : "zh";
      localStorage.setItem("lang", _lan);
      this.$i18n.locale = _lan;
      this.language = _lan;
    },
    // 返回首页
    backHome() {
      this.$router.push({
        path: "/"
      });
    },
    // 返回文理学院
    backArtsHome() {
      this.$router.push({
        path: "/arts"
      });
    }
  }
};
</script>

<style lang="less">
header {
  width: 100%;
  background: #5167dc;
  .app-header {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
  }
  .topTab {
    position: absolute;
    left: 50%;
    margin-left: -350px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 50px;
    span {
      cursor: pointer;
      & + span {
        margin-left: 40px;
      }
    }
  }
  .logoImg {
    position: absolute;
    width: 120px;
    height: 28px;
    top: 11px;
    left: 50%;
    margin-left: -570px;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  .headerNav {
    position: absolute;
    right: 50%;
    margin-right: -570px;
    display: flex;
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 50px;
    span {
      cursor: pointer;
    }
  }
}
</style>
