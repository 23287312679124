<template>
  <div class="homePage">
    <div class="topSelect">
      <div class="universitySearchBox">
        <el-input
          :placeholder="$t('placeholder.schoolSearchText')"
          size="mini"
          v-model="params.name"
          @input="schoolInputChange"
          clearable
        >
        </el-input>
      </div>
    </div>
    <div class="page-container homeCountBox">
      <div class="rankCunt" v-loading="loading">
        <el-table :data="rankData" style="width: 100%">
          <el-table-column
            prop="xh"
            :label="$t('indexPage.tableIndex')"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="xh_g"
            :label="$t('indexPage.globalXhText')"
            width="80"
            v-if="requireParams.country || requireParams.continent"
          >
          </el-table-column>
          <el-table-column :label="$t('indexPage.universityName')">
            <template slot-scope="scope">
              <p class="name" @click="nameClick(scope.row)">
                {{ locale === "zh" ? scope.row.cnName : scope.row.enName }}
              </p>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$t('placeholder.countrySearchTitle')">
            <template slot-scope="scope">
              <img :src="scope.row.image_country" alt="" class="countryImg" />
              <span class="countryName">{{
                locale === "zh" ? scope.row.country_cn : scope.row.country_en
              }}</span>
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('indexPage.scoreText')" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.score | numToFiexd }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNow"
        :page-sizes="[20, 25, 30, 50]"
        :page-size="params.pageSize"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRankList } from "@/API/getData";

export default {
  name: "home",
  data() {
    return {
      loading: true,
      rankData: [],
      total: 0,
      language: "zh",
      params: {
        name: "",
        queryType: "liberal-arts",
        pageSize: 20,
        pageNow: 1
      },
      requireParams: {}
    };
  },
  filters: {
    numToFiexd(val) {
      return (val - 0).toFixed(2);
    },
    weightFilter(val) {
      return (val * 100).toFixed(1) + "%";
    }
  },
  computed: {
    locale: function() {
      return this.$i18n.locale;
    }
  },
  watch: {
    locale(newVal) {
      console.log(newVal);
      this.loading = true;
      this.name = "";
      this.getRankList();
    }
  },
  mounted() {
    this.region = this.locale
      ? this.locale === "zh"
        ? "所有"
        : "All"
      : "所有";
    this.getRankList();
  },
  methods: {
    formatTooltip(val) {
      return `${val}%`;
    },
    handleSizeChange(val) {
      this.params.pageSize = this.requireParams.pageSize = val;
      this.params.pageNow = this.requireParams.pageNow = 1;
      this.getRankList();
    },
    handleCurrentChange(val) {
      this.params.pageNow = this.requireParams.pageNow = val;
      this.getRankList();
    },
    // 学校名称变化
    schoolInputChange() {
      this.params.pageNow = this.requireParams.pageNow = 1;
      this.requireParams.name = this.params.name;
      this.getRankList();
    },
    // 大学名称点击跳转大学详情页
    nameClick(data) {
      console.log(data);
      let routeData = this.$router.resolve({
        path: "/info",
        query: {
          cnName: data.cnName,
          enName: data.enName
        }
      });
      window.open(routeData.href, "_blank");
    },
    // 请求排行
    getRankList() {
      if (JSON.stringify(this.requireParams) == "{}") {
        this.requireParams = { ...{}, ...this.params };
      }
      let _opt = {
        language: this.locale,
        ...this.requireParams
      };
      let rankData = [];
      this.loading = true;
      getRankList(_opt)
        .then(res => {
          this.total = res.data.pageInfo.total;
          for (let key in res.data.info) {
            let _item = {
              enName: key,
              ...res.data.info[key]
            };
            rankData.push(_item);
          }
          rankData.sort((a, b) => {
            let _xh1 = a.xh;
            let _xh2 = b.xh;
            if (_xh1 > _xh2) {
              return 1;
            } else if (_xh1 < _xh2) {
              return -1;
            } else {
              return 0;
            }
          });
          this.loading = false;
          this.rankData = rankData;
        })
        .catch(rej => {
          this.loading = false;
          console.log(rej);
          this.total = 0;
          this.rankData = rankData;
        });
    }
  }
};
</script>
